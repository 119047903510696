import { IBundle, INote } from "core/types/bundles";
import { BundleReducerType } from "src/core/types/reduxTypes";

const initialState: IBundle = {
  name: "",
  title: {
    cs: null,
    de: null,
    en: null,
  },
  image_url: "",
  tours: [],
  items: [],
  is_favorite: false,
  note: {} as INote,
  discount: null,
  guide_price: null,
  position: 0,
  is_single: false,
};

//eslint-disable-next-line @typescript-eslint/default-param-last
export const BundleReducer = (state = initialState, action: BundleReducerType) => {
  switch (action.type) {
    case "SET_BUNDLE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_DISCOUNT":
      return {
        ...state,
        discount: action.payload,
      };
    default:
      return state;
  }
};
