import { useQuery } from "react-query";
import axios from "axios";
import { SLOTS_API_ROUTE } from "../endpoints";
import { getDateString } from "../helpers";

export const useGetSlots = ({ date }: { date: Date }) => {
  const dateString = date instanceof Date ? getDateString(date) : date;

  const fetchSlots = async (): Promise<any> => {
    const res = await axios.get(`https://visitjakub.cz/api/${SLOTS_API_ROUTE}/${dateString}`);
    return res.data.data;
  };

  return useQuery(`fetchSlots-${date}`, fetchSlots, {
    refetchOnWindowFocus: false,
    enabled: true,
  });
};
