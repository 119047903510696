import { Box, Button, MenuItem, Typography } from "@mui/material";
import React from "react";
import Menu from "@mui/material/Menu";
import hamburgerIcon from "../../../assets/hamburger.svg";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import close from "../../../assets/cross_close.svg";
import ticketIcon from "../../../assets/tickets_white.svg";
import { useNavigate } from "react-router-dom";
import { headerRoutes } from "src/core/utils";

const MobileMenu: React.FC<{
  VisitClick?: () => void;
  onButtonsClick?: () => void;
}> = ({ onButtonsClick, VisitClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToButtons = (route: string): void => {
    if (onButtonsClick) {
      onButtonsClick();
      handleMenuClose();
      navigate(route);
    }
  };

  const style = {
    menuItem: {
      display: "flex",
      alignItems: "flex-end",
      marginTop: "6px",
      fontSize: "20px",
      "&:hover": {
        backgroundColor: "#E31340",
      },
    },
    icon: {
      marginLeft: "auto",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div>
      <Button
        onClick={handleMenuOpen}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {isOpen ? (
          <img alt="close" src={close} style={{ cursor: "pointer" }} />
        ) : (
          <img alt="hamburgerIcon" src={hamburgerIcon} style={{ cursor: "pointer" }} />
        )}
      </Button>
      <Box sx={style.container}>
        <Menu
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "unset",
              position: "absolute",
              top: "102px !important",
              left: "1px !important",
              width: "100dvw",
              padding: "10px 25px",
              border: "none",
              backgroundColor: "#E31340",
              borderRadius: "1px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              "@media (max-width: 768px)": {
                top: "81px !important",
              },
            },
            "& .MuiMenuItem-root": {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              textAlign: "right",
              backgroundColor: "#E31340",
              color: "#FFFFFF",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "#E31340",
                color: "#FFFFFF",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#E31340",
                color: "#FFFFFF",
              },
            },
          }}
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/bundle-type")}>
              <Typography margin={"0 20px 0 0"} sx={{ cursor: "pointer" }}>
                {t("purchase_button")}
              </Typography>
              <img alt="ticketIcon" src={ticketIcon} style={style.icon} />
            </MenuItem>
            {headerRoutes.map((route) => (
              <MenuItem
                key={route.path}
                sx={style.menuItem}
                onClick={() => navigateToButtons(route.path)}
              >
                <Typography
                  sx={[{ cursor: "pointer" }, route.icon ? { margin: "0 20px 0 0" } : {}]}
                >
                  {t(route.label)}
                </Typography>
                {route.icon && <img alt="ticketIcon" src={route.icon} style={style.icon} />}
              </MenuItem>
            ))}
            <MenuItem sx={style.menuItem} onClick={() => navigateToButtons("/gift-cards")}>
              <Typography sx={{ cursor: "pointer" }}>{t("gift_cards")}</Typography>
            </MenuItem>
            <MenuItem
              sx={style.menuItem}
              onClick={() => {
                open("https://helpdesk.visitjakub.cz/desk");
              }}
            >
              <Typography sx={{ cursor: "pointer" }}>{t("visitor_infoservice")}</Typography>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </div>
  );
};
export default MobileMenu;
