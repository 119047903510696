import React from "react";
import {
  Discount,
  Header,
  HeaderText,
  HorizontalLine,
  Logo,
  MiddleWrapper,
  PriceWrapper,
  SlotName,
  SlotPrice,
  TotalPrice,
  TotalPriceCash,
  TotalPriceItem,
  TotalPriceText,
  TotalPriceWrapper,
  Wrapper,
} from "src/components/atoms/TicketsReceipt/styledComponents";

import logo from "../../../assets/logo_pos.svg";
import { IBundle, ITour, SlotKey } from "core/types/bundles";
import { Discount as DiscountType } from "src/core/types";
import { getDiscountPrice } from "src/core/utils/getDiscountPrice";
import { Loc } from "src/core/types/loc";
import { useTranslation } from "react-i18next";

const TicketsReceipt = ({
  getTourPrice,
  bundle,
  isRecapPayment = false,
  discount,
}: {
  getTourPrice: (tour: SlotKey) => number;
  bundle: IBundle;
  isRecapPayment?: boolean;
  discount?: DiscountType;
}) => {
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const getTotalPrice = () => {
    let total = 0;
    bundle.tours.forEach((tour: ITour) => (total += getTourPrice(tour.name)));
    sessionStorage.setItem(
      "totalPrice",
      String(getDiscountPrice({ price: total, percentage: bundle.discount })),
    );
    return total;
  };

  const getDiscount = (): string => {
    if (discount && discount.type === "flat") {
      return `${discount.amount} ${t("cz_currency")}`;
    }
    return `${bundle.discount} %`;
  };
  return (
    <Wrapper isRecapPayment={isRecapPayment}>
      {!isRecapPayment && (
        <Header>
          <Logo src={logo} alt="logo" />
          <HeaderText>{t("tickets")}</HeaderText>
        </Header>
      )}
      <MiddleWrapper isRecapPayment={isRecapPayment}>
        {bundle.tours.map((tour: ITour, i: number) => (
          <PriceWrapper key={tour.name}>
            <SlotName>{bundle.items[i].name[loc]}</SlotName>
            <SlotPrice>{`${getTourPrice(tour.name)} ${t("cz_currency")}`}</SlotPrice>
          </PriceWrapper>
        ))}
      </MiddleWrapper>
      <HorizontalLine />
      <TotalPriceWrapper>
        {(bundle.discount || (discount && discount.amount > 0)) && (
          <>
            <TotalPriceItem>
              <TotalPriceText>{t("total_price")}</TotalPriceText>
              <TotalPriceText>{`${getTotalPrice()} ${t("cz_currency")}`}</TotalPriceText>
            </TotalPriceItem>
            <TotalPriceItem>
              <Discount>{t("sale")}</Discount>
              <Discount>{getDiscount()}</Discount>
            </TotalPriceItem>
          </>
        )}
        <TotalPriceItem>
          <TotalPrice>{t("final_price")}</TotalPrice>
          <TotalPrice>
            <TotalPriceCash>
              {`${
                getTotalPrice() - (discount?.amount || 0) < 0
                  ? 0
                  : getTotalPrice() - (discount?.amount || 0)
              } ${t("cz_currency")}`}
            </TotalPriceCash>
          </TotalPrice>
        </TotalPriceItem>
      </TotalPriceWrapper>
    </Wrapper>
  );
};

export default TicketsReceipt;
