import axios from "axios";
import { TIMETABLE } from "src/core/endpoints";
import { useQuery } from "react-query";

interface ITimeTable {
  fara_from: string;
  fara_monday_from: string;
  fara_monday_to: string;
  fara_to: string;
  id: number;
  liturgy_time_jezuit: string;
  liturgy_time_saturday: string;
  liturgy_time_slovak: string;
  liturgy_time_sunday: string;
  liturgy_time_week: string;
  open_from: string;
  open_to: string;
}

export const useGetTimeTable = () => {
  const fetchTimeTable = async (): Promise<ITimeTable> => {
    const repsonse = await axios.get(`https://visitjakub.cz/api/${TIMETABLE}`);
    return repsonse.data;
  };

  const { data, status } = useQuery("get-time-table", fetchTimeTable, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: true,
  });
  return { data, status };
};
