import { useQuery } from "react-query";
import axios from "axios";
import { DISCOUNT_API_ROUTE } from "../endpoints";
import { Discount, DiscountResult } from "../types";

export const useGetDiscount = ({
  code,
  onSuccess,
  onError,
}: {
  code: string | null;
  onSuccess?: (data: Discount) => void;
  onError?: (error: DiscountResult) => void;
}) => {
  const fetchDiscount = async (): Promise<Discount> => {
    const res = await axios.get(`https://visitjakub.cz/api/${DISCOUNT_API_ROUTE}/${code}`);
    return res.data;
  };
  return useQuery(`fetchDiscount-${code}`, fetchDiscount, {
    onSuccess: (data) => {
      if (data.result === "OK" && onSuccess) {
        onSuccess(data);
      } else {
        if (onError) onError(data.result);
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
    refetchInterval: 2,
  });
};
