import { useMutation } from "react-query";
import axios from "axios";
import { ASK_QUESTION } from "../endpoints";

interface IQuestion {
  sender: string;
  body: string;
}

export const useAskQuestion = () => {
  return useMutation((mutateData: IQuestion) => {
    return axios.post(`https://visitjakub.cz/api/${ASK_QUESTION}`, mutateData);
  });
};
