import { useQuery } from "react-query";
import axios from "axios";
import { STATUS_API_ROUTE } from "../endpoints";
import { Payment } from "../types/payment";

export const useGetPaymentResult = ({ reservationId }: { reservationId: string }) => {
  const fetchPayment: () => Promise<Payment> = async () => {
    const res = await axios.get(`https://visitjakub.cz/api/${STATUS_API_ROUTE}/${reservationId}`);
    return res.data;
  };

  return useQuery(`fetchPayment-${reservationId}`, fetchPayment, {
    refetchOnWindowFocus: false,
    enabled: !!reservationId,
    refetchInterval: 5000,
  });
};
